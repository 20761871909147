import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../servicess/auth.service'
@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.css']
})
export class ContactformComponent implements OnInit {
  @Input() Show: boolean;
  angForm: FormGroup;

  constructor(private fb: FormBuilder, private auth: AuthService) {
    this.createForm();

  }
validation(Show:boolean)
{
  debugger;
  if(Show==true)
  { this.angForm.controls['mobile'].setValidators([
    Validators.required,Validators.pattern(new RegExp('[1-9][0-9 ]{9}')),
  ]);
    this.angForm.controls['mobile'].setErrors({
      required: true,
    });
    this.angForm.controls['message'].setErrors(null);
    this.angForm.controls['message'].clearValidators();
  }else{
    this.angForm.controls['message'].setValidators([
      Validators.required,
    ]);
    this.angForm.controls['message'].setErrors({
      required: true,
    });
    this.angForm.controls['mobile'].setErrors(null);
    this.angForm.controls['mobile'].clearValidators();
  }
  this.angForm.controls['mobile'].updateValueAndValidity();
  this.angForm.controls['message'].updateValueAndValidity();
}
  ngOnInit(): void {
this.validation(this.Show);
  }
  createForm(): void {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile: ['',[Validators.required,Validators.pattern(new RegExp('[1-9][0-9 ]{9}'))]],
      message: ['',Validators.required]
    });
  }
  onSubmit(): void {
    //console.log(this.angForm.value);
    this.angForm.addControl('date', new FormControl(this.currentDate(), Validators.required));

    this.auth.contact(this.angForm.value);
    this.onReset();
  }
  onReset(): void {
    this.angForm.reset({
      name: '',
      email: '',
      mobile: '',
      message: ''
    });
  }
  currentDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().substring(0, 10);
  }

}
