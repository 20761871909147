import { Component, OnInit } from '@angular/core';
import { CommenService } from '../servicess/commen.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  detailInfo: any;
  constructor(private comm: CommenService) { }

  ngOnInit(): void {
    this.detailInfo = this.comm.OwnerDetail();
  }
  scrollClick(e: any) {
    localStorage.setItem('scrollid', e);
    var elmnt = document.getElementById(e);
    var y = (elmnt?.scrollHeight || 0) + 200;
    let scrollY = elmnt?.scrollTop;
    elmnt?.scrollIntoView({ inline: 'start' });
    // elmnt?.scrollBy({top:y})
    console.log(scrollY);
  }
}
