
<div class="et_pb_text_inner" *ngIf="Show; else elseBlock">
    <div role=" form" class="wpcf7" id="wpcf7-f114-p5-o1" lang="en" dir="ltr">
        <div class="screen-reader-response"></div>
        <form [formGroup]="angForm" class="wpcf7-form" novalidate>
            <p><label> Name<br>
                    <span class="wpcf7-form-control-wrap your-name"><input type="text" formControlName="name" size="40"
                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true"
                            aria-invalid="false" placeholder="Name" /></span> </label>
            </p>
            <div *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)"
                class="alert">
                <div *ngIf="angForm.controls['name'].errors?.required">
                    name is required.
                </div>
            </div>
            <p><label> Email ID<br>
                    <span class="wpcf7-form-control-wrap your-email"><input type="email" formControlName="email" size="40"
                            class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                            aria-required="true" aria-invalid="false" placeholder="Email ID" /></span> </label>

            </p>
            <div *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)"
                class="alert">
                <div *ngIf="angForm.controls['email'].errors?.required">
                    email is required.
                </div>
                <div *ngIf="angForm.controls['email'].errors?.pattern">
                    enter valid email.
                </div>
            </div>
            <p><label> Mobile No<br>
                    <span class="wpcf7-form-control-wrap tel-578"><input type="tel" formControlName="mobile" size="40"
                            class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                            aria-required="true" aria-invalid="false" placeholder="Mobile No" /></span> </label>
            </p>
            <div *ngIf="angForm.controls['mobile'].invalid && (angForm.controls['mobile'].dirty || angForm.controls['mobile'].touched)"
                class="alert">
                <div *ngIf="angForm.controls['mobile'].errors?.required">
                    mobile is required.
                </div>
                <div *ngIf="angForm.controls['mobile'].errors?.pattern">
                    only number is allowed and enter minimum 10 number
                </div>
            </div>
            <p><input type="submit" (click)=[onSubmit()] [disabled]="angForm.invalid"
                    class="wpcf7-form-control wpcf7-submit" /></p>
            <div class="wpcf7-response-output wpcf7-display-none"></div>
        </form>
    </div>
</div>

<ng-template #elseBlock>
<div class="et_pb_contact">
    <form class="et_pb_contact_form clearfix" [formGroup]="angForm" novalidate>
        <p class="et_pb_contact_field et_pb_contact_field_0 et_pb_contact_field_half">


            <label for="et_pb_contact_name_0" class="et_pb_contact_form_label">Name</label>
            <input type="text" id="et_pb_contact_name_0" class="input" formControlName="name"
                data-required_mark="required" data-field_type="input" data-original_id="name" placeholder="Name">
            <span
                *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)"
                class="alert et_pb_contact_field et_pb_contact_field_1 et_pb_contact_field_half et_pb_contact_field_last">
                <span *ngIf="angForm.controls['name'].errors?.required">
                    name is required.
                </span>
            </span>
        </p>

        <p class="et_pb_contact_field et_pb_contact_field_1 et_pb_contact_field_half et_pb_contact_field_last">


            <label for="et_pb_contact_email_0" class="et_pb_contact_form_label">Email Address</label>
            <input type="text" id="et_pb_contact_email_0" class="input" formControlName="email"
                data-required_mark="required" data-field_type="email" data-original_id="email"
                placeholder="Email Address">
            <span
                *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)"
                class="alert">
                <span *ngIf="angForm.controls['email'].errors?.required">
                    email is required.
                </span>
                <span *ngIf="angForm.controls['email'].errors?.pattern">
                    enter valid email.
                </span>
            </span>
        </p>

        <p class="et_pb_contact_field et_pb_contact_field_2 et_pb_contact_field_last">


            <label for="et_pb_contact_message_0" class="et_pb_contact_form_label">Message</label>
            <textarea name="et_pb_contact_message_0" id="et_pb_contact_message_0" class="et_pb_contact_message input"
                formControlName="message" data-required_mark="required" data-field_type="text"
                data-original_id="message" placeholder="Message"></textarea>
            <span
                *ngIf="angForm.controls['message'].invalid && (angForm.controls['message'].dirty || angForm.controls['message'].touched)"
                class="alert">
                <span *ngIf="angForm.controls['message'].errors?.required">
                    message is required.
                </span>
            </span>
        </p>


        <div class="et_contact_bottom_container">

            <button type="submit" (click)=[onSubmit()] [disabled]="angForm.pristine || angForm.invalid"
                class="et_pb_contact_submit et_pb_button colorcalss">Submit</button>
                <a href="whatsapp://send?phone=+917065941332"
                class="et_pb_contact_submit et_pb_button colorcalss" style="text-decoration: none;"><i class="fab fa-whatsapp"></i> Chat Me</a>
        </div>
    </form>
</div> 
</ng-template>