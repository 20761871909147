<div class="container">
    <div class='row'>
        <!-- <div class='col-12 col-sm-12 col-xs-12 col-md-6'>
            <h2 class="text-primary" style="font-weight: 700;">Bank Details</h2>
            <div>
                <table class="table table-sm text-secondary" style="font-weight: 700;">
                    <tr>
                        <td>A/C Holder Name :</td>
                        <td>MOHIT R GUPTA & ASSOCIATES</td>
                    </tr>
                    <tr>
                        <td>BANK :</td>
                        <td>HDFC BANK LIMITED</td>
                    </tr>
                    <tr>
                        <td>IFSC :</td>
                        <td>HDFC0000709</td>
                    </tr>
                    <tr>
                        <td>A/C No.:</td>
                        <td>50200060345288</td>
                    </tr>
                </table>
            </div>
        </div> -->
        <div class='col-12 col-sm-12 col-xs-12 col-md-12 mb-5'>
            <h2 class="text-success">Accept Payment Only <span style="font-weight: bolder;" class="text-primary">QR Code
                    And UPI</span></h2>
            <script src='https://js.stripe.com/v2/' type='text/javascript'></script>
            <form accept-charset="UTF-8" class="require-validation" data-cc-on-file="false"
                data-stripe-publishable-key="pk_bQQaTxnaZlzv4FnnuZ28LFHccVSaj" id="payment-form" [formGroup]="angForm"
                novalidate>
                <div style="margin:0;padding:0;display:inline"><input name="utf8" type="hidden" value="✓" /><input
                        name="_method" type="hidden" value="PUT" /><input name="authenticity_token" type="hidden"
                        value="qLZ9cScer7ZxqulsUWazw4x3cSEzv899SP/7ThPCOV8=" /></div>
                <div class='form-row'>
                    <div class='col-xs-12 form-group required'>
                        <label class='control-label'>Name</label>

                        <input type="text" size='4' id="et_pb_contact_name_0" class="input form-control"
                            formControlName="name" data-required_mark="required" data-field_type="input"
                            data-original_id="name" placeholder="Name">
                        <div *ngIf="angForm.controls['name'].invalid && (angForm.controls['name'].dirty || angForm.controls['name'].touched)"
                            class="alert alert-danger">
                            <div *ngIf="angForm.controls['name'].errors?.required">
                                name is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div class='form-row'>
                    <div class='col-xs-12 form-group card required'>
                        <label class='control-label'>Mobile Number</label>
                        <input type="text" size='4' id="et_pb_contact_mobile_0" class="input" formControlName="mobile"
                            data-required_mark="required" data-field_type="mobile" data-original_id="mobile"
                            placeholder="mobile no">
                        <div *ngIf="angForm.controls['mobile'].invalid && (angForm.controls['mobile'].dirty || angForm.controls['mobile'].touched)"
                            class="alert alert-danger">
                            <div *ngIf="angForm.controls['mobile'].errors?.required">
                                mobile is required.
                            </div>
                            <div *ngIf="angForm.controls['mobile'].errors?.pattern">
                                only number is allowed and enter minimum 10 number
                            </div>
                        </div>
                    </div>
                </div>
                <div class='form-row'>
                    <div class='col-xs-12 form-group card required'>
                        <label class='control-label'>Email</label>
                        <input type="text" size='4' id="et_pb_contact_email_0" class="input" formControlName="email"
                            data-required_mark="required" data-field_type="email" data-original_id="email"
                            placeholder="Email Address">
                        <div *ngIf="angForm.controls['email'].invalid && (angForm.controls['email'].dirty || angForm.controls['email'].touched)"
                            class="alert alert-danger">
                            <div *ngIf="angForm.controls['email'].errors?.required">
                                email is required.
                            </div>
                            <div *ngIf="angForm.controls['email'].errors?.pattern">
                                enter valid email.
                            </div>
                        </div>
                    </div>
                </div>
                <div class='form-row'>
                    <div class='col-xs-12 form-group card required'>
                        <label class='control-label'>Amount</label>
                        <input (change)="amountChange($event)" type="text" size="4" ng-model="amount"
                            id="et_pb_contact_amount_0" class="input" formControlName="amount"
                            data-required_mark="required" data-field_type="amount" data-original_id="amount"
                            placeholder="Amount in INR">
                        <div *ngIf="angForm.controls['amount'].invalid && (angForm.controls['amount'].dirty || angForm.controls['amount'].touched)"
                            class="alert alert-danger">
                            <div *ngIf="angForm.controls['amount'].errors?.required">
                                Amount is required.
                            </div>
                            <div *ngIf="angForm.controls['amount'].errors?.pattern">
                                only number is allowed
                            </div>
                        </div>
                    </div>
                </div>
                <div class='form-row'>
                    <div class='col-xs-12 form-group card required'>
                        <label class='control-label'>Description</label>
                        <input type="text" size='4' id="et_pb_description_0" class="input" formControlName="description"
                             data-original_id="description"
                            placeholder="Enter Description here...">                       
                    </div>
                </div>

                <div class='form-row' *ngIf="amount>0">
                    <div class='col-md-12'>
                        <div class='form-control total btn btn-success'>
                            Total:
                            <span class='amount fas fa-rupee-sign'>{{amount}}</span>
                        </div>
                    </div>
                </div>
                <div class='form-row'>
                    <div class='col-md-12 mt-1 form-group'>
                        <button class='form-control btn btn-primary submit-button'
                            [disabled]="angForm.pristine || angForm.invalid" (click)="createRzpayOrder()"
                            type='submit'>Confirm & Pay »</button>
                    </div>
                </div>
            </form>
            <!-- <img src="../../assets/wp-content/uploads/2020/02/qrcode.jpeg" height="50%" width="50%"> -->
        </div>
        <!-- <div class='col-md-2'></div> -->
    </div>