import { Component, HostListener, OnInit } from '@angular/core';
import { CommenService } from '../servicess/commen.service';
import { loadscript } from '../servicess/loading-script';
import * as $ from 'jquery';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  detailInfo: any;
  constructor(private comm: CommenService) { }

  ngOnInit(): void {
    // loadscript();
    this.detailInfo = this.comm.OwnerDetail();

  }
  @HostListener('window:scroll', ['$event'])
  doSomething(event: any) {
    // console.debug("Scroll Event", document.body.scrollTop);
    // see András Szepesházi's comment below
    // console.debug("Scroll Event", window.pageYOffset);
  }
  scrollClick(e: any) {
    localStorage.setItem('scrollid', e);
    var elmnt = document.getElementById(e);
    var y = (elmnt?.scrollHeight || 0 )+200;
    let scrollY = elmnt?.scrollTop;
    elmnt?.scrollIntoView({inline: 'start'});
    // elmnt?.scrollBy({top:y})
    console.log(scrollY);  

  }


}
