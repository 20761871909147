import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../servicess/window-ref-universal.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../servicess/auth.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  providers: [WindowRefService]
})
export class PaymentComponent implements OnInit {

  constructor(private winRef: WindowRefService, private fb: FormBuilder, private auth: AuthService) { }
  angForm: FormGroup;
  rzp1: any;
  amount: number;
  ngOnInit() {
    this.createForm();
  }
  //4K0XT4o9UOXsw26d8hFmcRfm


  createForm(): void {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,
      Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile: ['', [Validators.required, Validators.pattern(new RegExp('[1-9][0-9 ]{9}'))]],
      amount: ['', [Validators.required]],
      description: ['']
    });
  }
  async createRzpayOrder() {
    // console.log(data);
    // call api to create order_id

    console.log(this.angForm.value);
    let formval = this.angForm.value;
    let form = this.angForm;
    // this.options.prefill.name = formval.name;
    // this.options.prefill.email = formval.email;
    // this.options.prefill.contact = formval.mobile;
    // this.options.amount = (formval.amount || 0) * 100;



    let options = {
      "key": "rzp_live_0rn4OoGQj4u7sc",//"rzp_test_WBVBJYVu8VxB2s", // Enter the Key ID generated from the Dashboard
      "amount": (formval.amount || 0) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "CA MOHIT GUPTA",
      "description": formval.description || "Transaction",
      "image": "../../assets/wp-content/uploads/2020/02/logo-1-1.png",
      "order_id": "",//new Date().getTime().toString(), //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": function (response: any) {
        console.log(response, "handler response");
        if (response) {
          // var html = `<html><head></head><body style="font-size:20px;color:blue;">          
          // Payment Id: ${response.razorpay_payment_id || ""}        
          // </body></html>`;
          // var uri = "data:text/html," + encodeURIComponent(html);
          // var newWindow = window.open(uri);
          alert("Payment Success. Payment Id: " + response.razorpay_payment_id)
          window.location.reload()
        }
      },
      "prefill": {
        "name": formval.name,
        "email": formval.email,
        "contact": formval.mobile
      },
      "notes": {
        "address": ""
      },
      "theme": {
        "color": "#3399cc"
      },
      // "modal": {
      //   "ondismiss": function () {
      //     if (confirm("Are you sure, you want to close the form?")) {
      //       console.log("You pressed OK!");
      //       console.log("Checkout form closed by the user");
      //     } else {
      //       console.log("You pressed Cancel!");
      //       console.log("Complete the Payment")
      //     }
      //   }
      // },
      config: {
        display: {
          blocks: {
            banks: {
              name: 'Pay via UPI',
              instruments: [
                {
                  method: 'upi'
                }
              ],
            },
          },
          sequence: ['block.banks'],
          preferences: {
            show_default_blocks: false,
          },
        },
      },

    };
    // console.log(response.razorpay_payment_id);
    // console.log(response.razorpay_order_id);
    // console.log(response.razorpay_signature)


    //     let orderdata = {"amount": this.options.amount,
    //     "currency": "INR",
    //     "receipt": ""};
    //     this.auth.getpaymentOrder(orderdata) .then((res:any)=>{
    //       console.log(res);
    // this.options.order_id = res?.id;
    //   })

    // let orderGenInstance = new this.winRef.nativeWindow.Razorpay({ key: 'rzp_test_WBVBJYVu8VxB2s', key_secret: 'HKllulHYnaZvkqVOagNgkNev' })

    // let order = orderGenInstance.orders.create({
    //   amount: this.options.amount,
    //   currency: "INR",
    //   receipt: "receipt#" + new Date().getTime().toString()

    // })
    // let re = this.auth.getpaymentOrder({
    //   amount: this.options.amount,
    //   currency: "INR",
    //   receipt: "receipt#" + new Date().getTime().toString()

    // })

    // fetch('https://api.razorpay.com/v1/orders', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     "Access-Control-Allow-Origin": "*",
    //      Authorization: "Basic " + this.winRef.nativeWindow.btoa('rzp_test_WBVBJYVu8VxB2s:HKllulHYnaZvkqVOagNgkNev'),
    //   },
    //   body: JSON.stringify({
    //     amount: this.options.amount,
    //     currency: "INR",
    //     receipt: "receipt#" + new Date().getTime().toString()

    //   })
    // }).then(res => res.json).then(data => {
    //   let dt = JSON.stringify(data);
    //   let obj = JSON.parse(dt);
    //   console.log(data, obj, "order api response")
    //   if (obj && obj?.status == "created") {
    //     this.options.order_id = obj.id || "";
    //     this.rzp1 = new this.winRef.nativeWindow.Razorpay(this.options);
    //     this.rzp1.on('payment.failed', function (response: any) {
    //       console.log(response)
    //       console.log(response.error.code);
    //       console.log(response.error.description);
    //       console.log(response.error.source);
    //       console.log(response.error.step);
    //       console.log(response.error.reason);
    //       console.log(response.error.metadata.order_id);
    //       console.log(response.error.metadata.payment_id);
    //     });

    //     this.rzp1.open();
    //   }
    // }).catch(err => console.log(err));




    this.rzp1 = new this.winRef.nativeWindow.Razorpay(options);
    this.rzp1.on('payment.failed', function (response: any) {
      console.log(response)
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    });

    this.rzp1.open();
  }
  amountChange(event: any) {
    this.amount = event.target.value;
  }
}
