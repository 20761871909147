import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor() { }
  showmore = false;
  ngOnInit(): void {
    let id = localStorage.getItem('scrollid') || '';
    var elmnt = document.getElementById(id);
    var y = (elmnt?.scrollHeight || 0 )-200;
    let scrollY = elmnt?.scrollTop;
    elmnt?.scrollIntoView({block: 'start'});
    //elmnt?.scrollTo({behavior:'smooth',top:y})
  }
readmore():void{
  this.showmore = true;
}
}
