<footer id="main-footer"
    style="font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important">

    <div class="container">
        <div id="footer-widgets" class="clearfix footeratag">
            <div class="footer-widget">
                <div id="text-2" class="fwidget et_pb_widget widget_text">
                    <h4 class="title">External Links</h4>
                    <div class="textwidget">
                        <p id="foot-wiDd">
                            <script data-cfasync="false"
                                src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
                            <script src="https://kit.fontawesome.com/a076d05399.js"></script><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a href="https://www.icai.org/">
                                ICAI</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a href="https://www.rbi.org.in/">
                                Reserve bank of india</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a
                                href="https://www.incometaxindiaefiling.gov.in/home"> Income tax </a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a href="http://www.mca.gov.in/">
                                Ministery of corporate affairs</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a href="https://ewaybillgst.gov.in/">
                                E-way Bill System</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a
                                href="https://www.incometaxindia.gov.in/Pages/default.aspx"> Income Tax
                                Department</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a
                                href="https://services.gst.gov.in/services/login"> Goods and service tax</a>
                        </p>
                    </div>
                </div> <!-- end .fwidget -->
            </div> <!-- end .footer-widget -->
            <div class="footer-widget">
                <div id="text-3" class="fwidget et_pb_widget widget_text">
                    <h4 class="title">EXTRA LINKS</h4>
                    <div class="textwidget">
                        <p id="foot-wiDd">
                            <script src="https://kit.fontawesome.com/a076d05399.js"></script><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/home"> Home</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/aboutus"> About
                                Us</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services">
                                Services</a><br />
                            <!-- <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/blog"> Blog</a><br /> -->
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/contactus"> Contact
                                Us</a><br>
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/pay"> Payment</a>
                        </p><br>
                    </div>
                </div> <!-- end .fwidget -->
            </div> <!-- end .footer-widget -->
            <div class="footer-widget">
                <div id="text-5" class="fwidget et_pb_widget widget_text">
                    <h4 class="title">OUR SERVICES</h4>
                    <div class="textwidget">
                        <p id="foot-wiDd">
                            <script src="https://kit.fontawesome.com/a076d05399.js"></script><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services"
                                (click)="scrollClick('audit')"> Audit &amp; Assurance</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services"
                                (click)="scrollClick('direct')"> Direct Taxes</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services"
                                (click)="scrollClick('indirecttax')"> Indirect Taxes</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services"
                                (click)="scrollClick('business')"> Business Advisory</a><br />
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services"
                                (click)="scrollClick('accounting')"> Accounting</a><br>
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services"
                                (click)="scrollClick('mca')"> MCA/ROC</a><br>
                            <i class="fas fa-angle-right" aria-hidden="true"></i> <a routerLink="/services"
                                (click)="scrollClick('other')"> Other Services</a>
                        </p>
                    </div>
                </div> <!-- end .fwidget -->
            </div> <!-- end .footer-widget -->
            <div class="footer-widget">
                <div id="text-4" class="fwidget et_pb_widget widget_text">
                    <h4 class="title">CONTACT US</h4>
                    <div class="textwidget">
                        <p id="foot-wid">

                            <i class="fa fa-map-marker"></i> <b> Address:</b> {{this.detailInfo.address}}<br />
                            <i class="fa fa-mobile-phone"></i> <b> Mobile No:</b> <a
                                href="tel:{{detailInfo.mobileNo}}">{{this.detailInfo.mobileNo}}</a>
                        </p>
                    </div>
                </div> <!-- end .fwidget -->
            </div> <!-- end .footer-widget -->
        </div> <!-- #footer-widgets -->
    </div> <!-- .container -->


    <div id="footer-bottom">
        <div class="container clearfix">
            <ul class="et-social-icons">

                <!-- <li class="et-social-icon et-social-facebook">
            <a href="https://www.facebook.com/pages/Ahmad-Husain-Co/104690421129290" class="icon">
                <span>Facebook</span>
            </a>
        </li>
        <li class="et-social-icon et-social-instagram">
            <a href="https://www.instagram.com/ahcaindia/" class="icon">
                <span>Instagram</span>
            </a>
        </li> -->

            </ul>
            <div id="footer-info">© mrgassociates | Developed By <a href="https://neelgagantech.com/home"
                    target="_blank">Neelgagan Technologies</a></div>
        </div> <!-- .container -->
    </div>
</footer> <!-- #main-footer -->