import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'onlinetaxportal';
  constructor(
    private router: Router
  ) { }
  count: number = 0;
  routeslink = ['/home']
  ngOnInit() {
    // this.router.navigate(['service'])
    //   .then(() => {
    //     window.location.reload();
    //   });
    // this.router.routeReuseStrategy.shouldReuseRoute = () => true;
    this.router.events.subscribe(event => {
      // console.log(this.router.url);
      if (event instanceof NavigationEnd) {

        console.log(this.router.url);
        this.loadscript();
        // this.router.navigate([this.router.url]);
        if (this.routeslink.find(x => x === this.router.url)) {
          if (this.count == 1) {
            window.location.reload();
          }
          else
            this.count = this.count + 1;
        }
      }

    });
  }
  loadscript() {
    const dynamicScripts = [
      'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js',
      'assets/wp-includes/js/jquery/jquery4a5f.js',
      'assets/wp-includes/js/jquery/jquery-migrate.min330a.js',
      'assets/wp-content/plugins/contact-form-7/includes/js/scriptsb62d.js',
      'assets/a076d05399.js',
      'assets/wp-content/themes/Divi/js/custom.min8603.js',
      'assets/wp-content/themes/Divi/core/admin/js/common8603.js',
      'assets/wp-includes/js/wp-embed.min9dff.js',
      'assets/wp-includes/js/mediaelement/mediaelement-and-player.minc270.js',
      'assets/wp-includes/js/mediaelement/mediaelement-migrate.min9dff.js',
      'assets/wp-includes/js/mediaelement/wp-mediaelement.min9dff.js',
      'assets/variable.js',
      'https://twemoji.maxcdn.com/v/latest/twemoji.min.js',      
      'https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js'
    ];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < dynamicScripts.length; i++) {
      if (document.getElementById('dynamicload' + i) != null) {
        document.getElementById('dynamicload' + i)?.remove();
      }
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      node.id = 'dynamicload' + i;
      //  document.getElementsByClassName('pcoded-container')[0].appendChild(node);
      document.getElementsByTagName('body')[0].appendChild(node);
    }
  }
}
