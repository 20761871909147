<article id="post-159" class="post-159 page type-page status-publish hentry">


    <div class="entry-content">
        <div id="et-boc" class="et-boc">

            <div class="et-l et-l--post">
                <div class="et_builder_inner_content et_pb_gutters3">
                    <div class="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular">




                        <div class="et_pb_row et_pb_row_0">
                            <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_0 et_pb_bg_layout_light  et_pb_text_align_left">



                                </div> <!-- .et_pb_text -->
                                <div
                                    class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <!-- <div class="et_pb_text_inner">
                                        <h2>Services</h2>
                                    </div> -->
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->


                    </div> <!-- .et_pb_section -->
                    <div class="et_pb_section et_pb_section_1 et_section_regular">


<div class="text-center mt-3"><h2 class="text-secondary" style="font-weight: 700;">Services</h2></div>

                        <div id="audit" class="et_pb_row et_pb_row_1 et_pb_gutters1">
                            <div
                                class="et_pb_column et_pb_column_2_3 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough">


                                <div class="et_pb_module et_pb_image et_pb_image_0">


                                    <span class="et_pb_image_wrap "><img
                                            src="/assets/wp-content/uploads/2020/02/jpeg/Audit.jpg" alt="" title=""
                                            srcset="" sizes="" /></span>
                                </div>
                            </div> <!-- .et_pb_column -->
                            <div
                                class="et_pb_column et_pb_column_1_3 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_2 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h4>Audit &amp; Assurance</h4>
                                        <p>Extensive experience in providing assurance services under various statutes
                                            across various industries for the past several years.</p>
                                        <p><b>RISK ADVISORY, INTERNAL &amp; MANAGEMENT AUDITS</b><br />
                                            <ul>
                                          <li> Comprehensive evaluation of internal controls, internal systems and internal
                                            processes in organizations and recommending areas for improvement.</li>
                                            <li *ngIf="showmore"> Vastly experienced audit professionals follow a risk-based approach
                                            that involves risk assessment of the entity’s business &amp; its key
                                            business process.</li>
                                            <li *ngIf="showmore"> Risk based internal audit helps the risk management function of the
                                            entity by providing assurance about the risk mitigation and provides
                                            assurance to the management</li>
                                        </ul>
                                        <button class="btn-sm btn-success showbutton"  *ngIf="!showmore"  (click)="readmore()">Read More...</button>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->
                        <div id="directtax" class="et_pb_row et_pb_row_2 et_pb_gutters1">
                            <div
                                class="et_pb_column et_pb_column_1_3 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_3 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h4>Direct Taxes</h4>
                                        All areas of taxation : Personal Tax, Corporate Tax. | Suggesting legal
                                        structure of business entities. | Transactional advisory – optimal deal
                                        structure | Tax Due Diligence<br>
                                        <b>INTERNATIONAL DESK</b><br>
                                        <ul>
                                        <li> Suggesting entry options to foreign entities.</li> 
                                        <li>Assistance in seeking approvals for Liaison Office, Project Office,</li>
                                        <li>Branch Office, and formation of subsidiaries and JVCs.</li>
                                        <li> Other regulatory compliances for foreign entities.</li>
                                        <li> Handholding in Compliances.</li>
                                        <li> Advising on salary structure of expatriate employees</li>
                                    </ul>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->
                            <div
                                class="et_pb_column et_pb_column_2_3 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div class="et_pb_module et_pb_image et_pb_image_1">


                                    <span class="et_pb_image_wrap "><img
                                            src="/assets/wp-content/uploads/2020/02/jpeg/INCOME_TAX.jpg" alt="" title=""
                                            srcset="" sizes="" /></span>
                                </div>
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->
                        <div id="indirecttax" class="et_pb_row et_pb_row_3 et_pb_gutters1">
                            <div
                                class="et_pb_column et_pb_column_2_3 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough">


                                <div class="et_pb_module et_pb_image et_pb_image_2">


                                    <span class="et_pb_image_wrap "><img
                                            src="/assets/wp-content/uploads/2020/02/jpeg/Gst.jpg" alt="" title=""
                                            srcset="" sizes="" /></span>
                                </div>
                            </div> <!-- .et_pb_column -->
                            <div
                                class="et_pb_column et_pb_column_1_3 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_4 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner" >
                                        <h4>Indirect Taxes</h4>
                                        Advising client on establishment of indirect tax management system, in all areas
                                        i.e. GST, Customs, Foreign Trade Policies and Old Laws (Excise, Service Tax
                                        &#038; VAT)
                                        Suggesting legal structure of business transactions<br>

                                        <b>GST REVIEW / INTERNAL AUDIT</b><br>
                                       <ul><li>Taxability of transactions.</li>
                                        <li> Documentation review/Audit.</li>
                                        <li>ITC compliances.</li>
                                        <li> Applicability of rates.</li>
                                        <li> Compliances processes. </li>
                                        </ul>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->
                        <div id="business" class="et_pb_row et_pb_row_4 et_pb_gutters1">
                            <div
                                class="et_pb_column et_pb_column_1_3 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_5 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h4>Business Advisory</h4>
                                        Business advisory services tackle all aspects of business from managing
                                        finances, marketing your product or service better and more widely, embarking on
                                        research and development activities, to improving your operations.<br>

                                        <b>Business Advisory service offerings </b>
                                       
                                        <ul>
                                          <li>Business consulting including market research, strategy and operations</li>
                                        <li>
                                            Financial and process consulting</li>
                                        <li>
                                            Greenfield and brownfield projects advisory</li>
                                        <li>
                                            Transaction advisory including M&#038;A, due diligence, valuations,
                                            economic analysis</li>
                                        <li>
                                            Technology and risk consulting</li>
                                        </ul>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->
                            <div
                                class="et_pb_column et_pb_column_2_3 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div class="et_pb_module et_pb_image et_pb_image_3">


                                    <span class="et_pb_image_wrap "><img
                                            src="/assets/wp-content/uploads/2020/02/jpeg/Capture31.jpg" alt="" title="" srcset=""
                                            sizes="" /></span>
                                </div>
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->
                        <div id="accounting" class="et_pb_row et_pb_row_4 et_pb_gutters1">
                            <div
                                class="et_pb_column et_pb_column_2_3 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div class="et_pb_module et_pb_image et_pb_image_3">


                                    <span class="et_pb_image_wrap "><img
                                            src="/assets/wp-content/uploads/2020/02/jpeg/accounting.jpg" alt="" title="" srcset=""
                                            sizes="" /></span>
                                </div>
                            </div> <!-- .et_pb_column -->
                            <div
                                class="et_pb_column et_pb_column_1_3 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_5 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h4>Accounting</h4>
                                        <p> We offers services of Maintainance of Accounts for your business</p>
                                        <ul>
                                            <li>Timely and Accurate as Per Accounting Standards and Indian GAAP.
                                            </li>
                                            <li>Payroll Management</li>
                                            <li>Remotely online accounting onsite.</li>
                                        </ul>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->
                           


                        </div> <!-- .et_pb_row -->
                        <div id="mca" class="et_pb_row et_pb_row_3 et_pb_gutters1">
                          
                            <div
                                class="et_pb_column et_pb_column_1_3 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_4 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h4>MCA/ ROC</h4>
                                        <p>We have rich experience in handling all MCA and ROC Compliances like-						
                                            Incorporation of New Company/ LLP/ Trust/ NGO						
                                            Annual ROC forms- MGT-7 and AOC-4.						
                                            Other forms like- ADT-1 ,INC-22, DIR-3, MGT-14.						
                                        </p>
                                        
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->
                            <div
                            class="et_pb_column et_pb_column_2_3 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough">


                            <div class="et_pb_module et_pb_image et_pb_image_2">


                                <span class="et_pb_image_wrap "><img
                                        src="/assets/wp-content/uploads/2020/02/jpeg/MCA-naming-guidelines.jpg" alt="" title=""
                                        srcset="" sizes="" /></span>
                            </div>
                        </div> <!-- .et_pb_column -->

                        </div> <!-- .et_pb_row -->
                        <div id="other" class="et_pb_row et_pb_row_4 et_pb_gutters1">
                            <div
                                class="et_pb_column et_pb_column_2_3 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div class="et_pb_module et_pb_image et_pb_image_3">


                                    <span class="et_pb_image_wrap "><img
                                            src="/assets/wp-content/uploads/2020/02/jpeg/other-services-garant.jpg" alt="" title="" srcset=""
                                            sizes="" /></span>
                                </div>
                            </div> <!-- .et_pb_column -->

                            <div
                                class="et_pb_column et_pb_column_1_3 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_5 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h4>Other Services</h4>
                                        <p>We also provide additional services like</p>
                                        <ul>
                                            <li>TradeMark/ FSSAI Registration.
                                            </li>
                                            <li>Preparation of Project Reports/ CMA reports</li>
                                            <li>ESI/ PF/ MSME Registration.</li>
                                        </ul>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->
                           

                        </div> <!-- .et_pb_row -->

                    </div> <!-- .et_pb_section -->
                </div><!-- .et_builder_inner_content -->
            </div><!-- .et-l -->


        </div><!-- #et-boc -->
    </div> <!-- .entry-content -->


</article> <!-- .et_pb_post -->