<article id="post-153" class="post-153 page type-page status-publish hentry">


    <div class="entry-content">
        <div id="et-boc" class="et-boc">

            <div class="et-l et-l--post">
                <div class="et_builder_inner_content et_pb_gutters3">
                    <div class="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular">




                        <div class="et_pb_row et_pb_row_0">
                            <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_0 et_pb_bg_layout_light  et_pb_text_align_left">



                                </div> <!-- .et_pb_text -->
                                <div
                                    class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <!-- <div class="et_pb_text_inner colorclass">
                                        <h2>About Us</h2>
                                    </div> -->
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->


                    </div> <!-- .et_pb_section -->
                    <div class="et_pb_section et_pb_section_1 et_section_regular">




                        <div class="et_pb_row et_pb_row_1">
                            <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_2 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h2 class="aboutusheading">About Us</h2>
                                       <div class="aboutuscontent text-secondary"> <span class="text-primary">Mohit R Gupta & Associates (Chartered Accountants)</span> is an esteemed chartered accountant firm located in New
                                        Delhi. The firm has well experienced team specialised in direct and indirect
                                        taxation, accounting, banking and company matters.Our firm of chartered
                                        accountants represents a coalition of specialized skills that is geared to offer
                                        sound financial solutions and advices. The organization is a congregation of
                                        professionally qualified and experienced persons who are committed to add value
                                        and optimize the benefits accruing to clients.Our client list includes domestic
                                        and international entities of various sizes from different industries. Our team
                                        of experienced professionals provide financial solutions in a manner where
                                        client satisfaction is our top priority.
                                    </div>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->


                    </div> <!-- .et_pb_section -->
                </div><!-- .et_builder_inner_content -->
            </div><!-- .et-l -->


        </div><!-- #et-boc -->
    </div> <!-- .entry-content -->


</article> <!-- .et_pb_post -->