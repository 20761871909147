
<ngb-carousel *ngIf="images">
    <ng-template ngbSlide *ngFor="let imgs of images">
        <div class="wrapper">
            <img [src]="imgs.img" alt="not found">
        </div>
        <div class="carousel-caption">
          {{imgs.cap}}
        </div>
    </ng-template>
</ngb-carousel> 

