<article id="post-163" class="post-163 page type-page status-publish hentry">


    <div class="entry-content">
        <div id="et-boc" class="et-boc">

            <div class="et-l et-l--post">
                <div class="et_builder_inner_content et_pb_gutters3">
                    <div class="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular">




                        <div class="et_pb_row et_pb_row_0">
                            <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_0 et_pb_bg_layout_light  et_pb_text_align_left">



                                </div> <!-- .et_pb_text -->
                                <div
                                    class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h2>Our Blogs</h2>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->


                    </div> <!-- .et_pb_section -->
                    <div class="et_pb_section et_pb_section_1 et_section_regular">




                        <div class="et_pb_row et_pb_row_1">
                            <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div class="et_pb_module et_pb_blog_0 et_pb_blog_grid_wrapper">
                                    <div class="et_pb_blog_grid clearfix et_pb_bg_layout_light ">


                                        <div class="et_pb_ajax_pagination_container">
                                            <div class="et_pb_salvattore_content" data-columns>
                                                <article id="post-334"
                                                    class="et_pb_post clearfix post-334 post type-post status-publish format-standard has-post-thumbnail hentry category-news-artical">

                                                    <div class="et_pb_image_container"><a
                                                            href="#"
                                                            class="entry-featured-image-url"><img
                                                                src="/assets/wp-content/uploads/2020/04/covid-19-400x250.jpg"
                                                                alt="The novel coronavirus (COVID-19) pandemic: 5 financial reporting issues to consider"
                                                                class=""
                                                                srcset="/assets/wp-content/uploads/2020/04/covid-19.jpg 479w, /assets/wp-content/uploads/2020/04/covid-19-400x250.jpg 480w "
                                                                sizes="(max-width:479px) 479w, 100vw " width='400'
                                                                height='250' /></a></div>
                                                    <!-- .et_pb_image_container -->
                                                    <h2 class="entry-title"><a
                                                            href="#">The
                                                            novel coronavirus (COVID-19) pandemic: 5 financial reporting
                                                            issues to consider</a></h2>

                                                    <p class="post-meta"></p>
                                                    <div class="post-content">
                                                        <div class="post-content-inner et_multi_view_hidden">
                                                            <p>Companies/Businesses need to monitor the current and
                                                                potential effects that the novel coronavirus (COVID-19)
                                                                will have on their financial reporting. Timely and
                                                                meaningful disclosures about the potential effect on the
                                                                financial position, performance and viability of...</p>
                                                        </div>
                                                    </div>
                                                </article> <!-- .et_pb_post -->

                                                <article id="post-322"
                                                    class="et_pb_post clearfix post-322 post type-post status-publish format-standard has-post-thumbnail hentry category-news-artical">

                                                    <div class="et_pb_image_container"><a
                                                            href="#"
                                                            class="entry-featured-image-url"><img
                                                                src="/assets/wp-content/uploads/2020/03/spice.png"
                                                                alt="New MCA Filing Form SPICe+ (SPICe Plus) for Company Registration"
                                                                class=""
                                                                srcset="/assets/wp-content/uploads/2020/03/spice.png 479w, /assets/wp-content/uploads/2020/03/spice.png 480w "
                                                                sizes="(max-width:479px) 479w, 100vw " width='400'
                                                                height='250' /></a></div>
                                                    <!-- .et_pb_image_container -->
                                                    <h2 class="entry-title"><a
                                                            href="#">New
                                                            MCA Filing Form SPICe+ (SPICe Plus) for Company
                                                            Registration</a></h2>

                                                    <p class="post-meta"></p>
                                                    <div class="post-content">
                                                        <div class="post-content-inner et_multi_view_hidden">
                                                            <p>SPICe+ would offer 10 services by 3 Central Govt
                                                                Ministries &amp; Departments. (Ministry of Corporate
                                                                Affairs, Ministry of Labour &amp; Department of Revenue
                                                                in the Ministry of Finance) and One State
                                                                Govt.(Maharashtra), thereby saving as many procedures,
                                                                time and cost...</p>
                                                        </div>
                                                    </div>
                                                </article> <!-- .et_pb_post -->

                                                <article id="post-89"
                                                    class="et_pb_post clearfix post-89 post type-post status-publish format-standard has-post-thumbnail hentry category-news-artical">

                                                    <div class="et_pb_image_container"><a
                                                            href="#"
                                                            class="entry-featured-image-url"><img
                                                                src="/assets/wp-content/uploads/2020/02/transporter-id-compulsory-for-generating-part-a-of-e-way-bill-1.jpg"
                                                                alt="Transporter Id Compulsory For Generating Part-a Of E-way Bill"
                                                                class=""
                                                                srcset="/assets/wp-content/uploads/2020/02/transporter-id-compulsory-for-generating-part-a-of-e-way-bill-1.jpg 479w, /assets/wp-content/uploads/2020/02/transporter-id-compulsory-for-generating-part-a-of-e-way-bill-1.jpg 480w "
                                                                sizes="(max-width:479px) 479w, 100vw " width='400'
                                                                height='250' /></a></div>
                                                    <!-- .et_pb_image_container -->
                                                    <h2 class="entry-title"><a
                                                            href="#">Transporter
                                                            Id Compulsory For Generating Part-a Of E-way Bill</a></h2>

                                                    <p class="post-meta"></p>
                                                    <div class="post-content">
                                                        <div class="post-content-inner et_multi_view_hidden"></div>
                                                    </div>
                                                </article> <!-- .et_pb_post -->

                                                <article id="post-80"
                                                    class="et_pb_post clearfix post-80 post type-post status-publish format-standard has-post-thumbnail hentry category-news-artical">

                                                    <div class="et_pb_image_container"><a
                                                            href="#"
                                                            class="entry-featured-image-url"><img
                                                                src="/assets/wp-content/uploads/2020/02/recent-amendments-in-gst-1.jpg"
                                                                alt="Recent Amendments in GST" class=""
                                                                srcset="/assets/wp-content/uploads/2020/02/recent-amendments-in-gst-1.jpg 479w,/assets/wp-content/uploads/2020/02/recent-amendments-in-gst-1.jpg 480w "
                                                                sizes="(max-width:479px) 479w, 100vw " width='400'
                                                                height='250' /></a></div>
                                                    <!-- .et_pb_image_container -->
                                                    <h2 class="entry-title"><a
                                                            href="#">Recent
                                                            Amendments in GST</a></h2>

                                                    <p class="post-meta"></p>
                                                    <div class="post-content">
                                                        <div class="post-content-inner et_multi_view_hidden">
                                                            <p>Procedure for recovery of dues of the earlier laws under
                                                                GST The CBIC has prescribed the procedure for the
                                                                recovery of dues of the earlier laws under the GST
                                                                regime. Section 142(6)(b) and Section 142(7)(a) of the
                                                                CGST Act, 2017 governs such recovery in the GST...</p>
                                                        </div>
                                                    </div>
                                                </article> <!-- .et_pb_post -->

                                                <article id="post-1"
                                                    class="et_pb_post clearfix post-1 post type-post status-publish format-standard has-post-thumbnail hentry category-news-artical">

                                                    <div class="et_pb_image_container"><a
                                                            href="#"
                                                            class="entry-featured-image-url"><img
                                                                src="./assets/wp-content/uploads/2020/02/rectification-facility-in-shipping-bills-for-sanctioning-igst-refunds-1.jpg"
                                                                alt="Rectification facility in shipping bills for sanctioning IGST Refunds"
                                                                class=""
                                                                srcset="/assets/wp-content/uploads/2020/02/rectification-facility-in-shipping-bills-for-sanctioning-igst-refunds-1.jpg 479w, /assets/wp-content/uploads/2020/02/rectification-facility-in-shipping-bills-for-sanctioning-igst-refunds-1.jpg 480w "
                                                                sizes="(max-width:479px) 479w, 100vw " width='400'
                                                                height='250' /></a></div>
                                                    <!-- .et_pb_image_container -->
                                                    <h2 class="entry-title"><a
                                                            href="#">Rectification
                                                            facility in shipping bills for sanctioning IGST Refunds</a>
                                                    </h2>

                                                    <p class="post-meta"></p>
                                                    <div class="post-content">
                                                        <div class="post-content-inner et_multi_view_hidden">
                                                            <p>The CBIC, earlier, provided a mechanism for exporters
                                                                with an officer interface to resolve invoice mismatching
                                                                for the shipping bills filed till 30.06.2018. The errors
                                                                were due to mismatching in the information furnished in
                                                                the tax invoices and the corresponding...</p>
                                                        </div>
                                                    </div>
                                                </article> <!-- .et_pb_post -->
                                            </div><!-- .et_pb_salvattore_content -->
                                        </div>
                                    </div> <!-- .et_pb_posts -->
                                </div>
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->


                    </div> <!-- .et_pb_section -->
                </div><!-- .et_builder_inner_content -->
            </div><!-- .et-l -->


        </div><!-- #et-boc -->
    </div> <!-- .entry-content -->


</article> <!-- .et_pb_post -->