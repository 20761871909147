<article id="post-161" class="post-161 page type-page status-publish hentry">


    <div class="entry-content">
        <div id="et-boc" class="et-boc">

            <div class="et-l et-l--post">
                <div class="et_builder_inner_content et_pb_gutters3">
                    <div class="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular">




                        <div class="et_pb_row et_pb_row_0">
                            <div
                                class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_0 et_pb_bg_layout_light  et_pb_text_align_left">



                                </div> <!-- .et_pb_text -->
                                <div
                                    class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <!-- <div class="et_pb_text_inner">
                                        <h2>Contact Us</h2>
                                    </div> -->
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->


                    </div> <!-- .et_pb_section -->
                    <div class="et_pb_section et_pb_section_1 et_section_regular">




                        <div class="et_pb_row et_pb_row_1">
                            <div
                                class="et_pb_column et_pb_column_3_5 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough">



                                <div id="et_pb_contact_form_0"
                                    class="et_pb_module et_pb_contact_form_0 et_pb_contact_form_container clearfix  et_pb_text_align_left"
                                    data-form_unique_num="0">


                                    <h1 class="et_pb_contact_main_title">Get In Touch</h1>
                                    <div class="et-pb-contact-message"></div>

                                    <div class="et_pb_contact">
                                        <!-- <form class="et_pb_contact_form clearfix" method="post"
                                            action="https://ahca.in/contact-us/">
                                            <p class="et_pb_contact_field et_pb_contact_field_0 et_pb_contact_field_half"
                                                data-id="name" data-type="input">


                                                <label for="et_pb_contact_name_0"
                                                    class="et_pb_contact_form_label">Name</label>
                                                <input type="text" id="et_pb_contact_name_0" class="input" value=""
                                                    name="et_pb_contact_name_0" data-required_mark="required"
                                                    data-field_type="input" data-original_id="name" placeholder="Name">
                                            </p>
                                            <p class="et_pb_contact_field et_pb_contact_field_1 et_pb_contact_field_half et_pb_contact_field_last"
                                                data-id="email" data-type="email">


                                                <label for="et_pb_contact_email_0"
                                                    class="et_pb_contact_form_label">Email Address</label>
                                                <input type="text" id="et_pb_contact_email_0" class="input" value=""
                                                    name="et_pb_contact_email_0" data-required_mark="required"
                                                    data-field_type="email" data-original_id="email"
                                                    placeholder="Email Address">
                                            </p>
                                            <p class="et_pb_contact_field et_pb_contact_field_2 et_pb_contact_field_last"
                                                data-id="message" data-type="text">


                                                <label for="et_pb_contact_message_0"
                                                    class="et_pb_contact_form_label">Message</label>
                                                <textarea name="et_pb_contact_message_0" id="et_pb_contact_message_0"
                                                    class="et_pb_contact_message input" data-required_mark="required"
                                                    data-field_type="text" data-original_id="message"
                                                    placeholder="Message"></textarea>
                                            </p>
                                            <p class="et_pb_contact_field et_pb_contact_field_3 et_pb_contact_field_half"
                                                data-id="et_number" data-type="input">
                                                <label for="et_pb_contact_et_number_0"
                                                    class="et_pb_contact_form_label">Number</label>
                                                <input type="text" id="et_pb_contact_et_number_0" class="input" value=""
                                                    name="et_pb_contact_et_number_0" data-required_mark="required"
                                                    data-field_type="input" data-original_id="et_number"
                                                    placeholder="Number" tabindex="-1" autocomplete="disabled">
                                            </p>
                                            <input type="hidden" value="et_contact_proccess"
                                                name="et_pb_contactform_submit_0" />
                                            <div class="et_contact_bottom_container">

                                                <button type="submit" name="et_builder_submit_button"
                                                    class="et_pb_contact_submit et_pb_button" 3>Submit</button>
                                            </div>
                                            <input type="hidden" id="_wpnonce-et-pb-contact-form-submitted-0"
                                                name="_wpnonce-et-pb-contact-form-submitted-0"
                                                value="be96d43d28" /><input type="hidden" name="_wp_http_referer"
                                                value="/contact-us/" />
                                        </form> -->
                                        <app-contactform [Show]="Show"></app-contactform>
                                    </div> <!-- .et_pb_contact -->
                                </div> <!-- .et_pb_contact_form_container -->

                            </div> <!-- .et_pb_column -->
                            <div
                                class="et_pb_column et_pb_column_2_5 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                <div
                                    class="et_pb_module et_pb_text et_pb_text_2 et_pb_bg_layout_light  et_pb_text_align_left">


                                    <div class="et_pb_text_inner">
                                        <h3>Contact Information</h3>
                                        <div>

                                            <strong>Phone:</strong><a href="tel:{{detailInfo.mobileNo}}">
                                                {{this.detailInfo.mobileNo}}</a> <br>
                                            <strong>Mobile:</strong><a href="tel:{{detailInfo.phoneNo}}">
                                                {{this.detailInfo.phoneNo}}</a><br>

                                            <strong>Email ID:</strong> <a href="mailto:{{detailInfo.email}}">
                                                {{this.detailInfo.email}}</a><br>

                                            <strong>Website:</strong> <a href="{{detailInfo.website}}" target="_blank">
                                                {{this.detailInfo.website}}</a><br>
                                            <strong>Address:</strong> <span class="text-primary">
                                                {{this.detailInfo.address}}</span>
                                        </div>
                                    </div>
                                </div> <!-- .et_pb_text -->
                            </div> <!-- .et_pb_column -->


                        </div> <!-- .et_pb_row -->


                    </div> <!-- .et_pb_section -->
                </div><!-- .et_builder_inner_content -->
            </div><!-- .et-l -->


        </div><!-- #et-boc -->
    </div> <!-- .entry-content -->


</article> <!-- .et_pb_post -->