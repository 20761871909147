import { AfterViewInit, Component,Input, OnInit } from '@angular/core';
import {loadscript} from '../servicess/loading-script';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit,AfterViewInit {
  Show:boolean = true;
  constructor() { }

  ngOnInit(): void {

  }
  ngAfterViewInit():void{
  //  loadscript();
  }
  scrollClick(e: any) {   
    localStorage.setItem('scrollid', e);

  }
}
