import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  formdata: any = [];
  result: any;
  constructor(private http: HttpClient) { }
  protected get requestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let encodedData = "Basic Auth " + window.btoa('rzp_test_WBVBJYVu8VxB2s:HKllulHYnaZvkqVOagNgkNev')
    console.log(encodedData)

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',     
      Authorization: encodedData,
      "Access-Control-Allow-Origin": "*",
      
    });

    return { headers };
  }
  contact(enqData: any): any {

    const url = 'https://script.google.com/macros/s/AKfycbylPGhlHBBiYEM-sU4Kq32bmLUgl8GYiXKCHIyw63eHXRRduqRv/exec';
    const httpHeaders = new HttpHeaders()
      .set('Accept', 'application/json');
    console.log(enqData, "Enqdata");
    this.http.get<any>(url, { headers: httpHeaders, observe: 'response', params: enqData, responseType: 'json' }).subscribe(data => {
      // console.log(data);
      this.result = data.body;
      if (this.result?.result === 'success') {
        alert('Thank you for contacting us...');
      }
      else {
        alert('Something wrong. Try Again!');
      }
      return this.result;
    });
  }
  async getpaymentOrder(data: any) {
    const url = 'https://api.razorpay.com/v1/orders';
    debugger
    return this.http.post<any>(url, data, this.requestHeaders).subscribe({
      next: res => {
        return res;
      },
      error: error => {
        console.error('There was an error!', error);
      }
    })
  }
}
