<div id="main-content">



    <article id="post-5" class="post-5 page type-page status-publish hentry">


        <div class="entry-content">
            <div id="et-boc" class="et-boc">

                <div class="et-l et-l--post">
                    <div class="et_builder_inner_content et_pb_gutters3">
                        <div class="et_pb_section et_pb_section_0 et_pb_fullwidth_section et_section_regular">




                            <div
                                class="et_pb_module et_pb_fullwidth_slider_0 et_pb_slider et_slider_auto et_slider_speed_2500">
                                <div class="et_pb_slides">
                                    <div class="et_pb_slide et_pb_slide_0 et_pb_bg_layout_dark et_pb_media_alignment_center et-pb-active-slide"
                                        data-slide-id="et_pb_slide_0">


                                        <div class="et_pb_container clearfix">
                                            <div class="et_pb_slider_container_inner">

                                                <div class="et_pb_slide_description">
                                                    <div class="et_pb_slide_content">
                                                        <div id="fs">
                                                            <h2 id="sli1">Growing your Business is now Easy</h2>
                                                        </div>
                                                    </div>

                                                </div> <!-- .et_pb_slide_description -->
                                            </div>
                                        </div> <!-- .et_pb_container -->

                                    </div> <!-- .et_pb_slide -->
                                    <div class="et_pb_slide et_pb_slide_1 et_pb_bg_layout_dark et_pb_media_alignment_center"
                                        data-slide-id="et_pb_slide_1">


                                        <div class="et_pb_container clearfix">
                                            <div class="et_pb_slider_container_inner">

                                                <div class="et_pb_slide_description">
                                                    <div class="et_pb_slide_content">
                                                        <div id="fs">
                                                            <h2 id="sli1">Helping Entrepreneurs to start their own
                                                                venture</h2>
                                                        </div>
                                                    </div>

                                                </div> <!-- .et_pb_slide_description -->
                                            </div>
                                        </div> <!-- .et_pb_container -->

                                    </div> <!-- .et_pb_slide -->
                                    <div class="et_pb_slide et_pb_slide_2 et_pb_bg_layout_dark et_pb_media_alignment_center"
                                        data-slide-id="et_pb_slide_2">


                                        <div class="et_pb_container clearfix">
                                            <div class="et_pb_slider_container_inner">

                                                <div class="et_pb_slide_description">
                                                    <div class="et_pb_slide_content">
                                                        <div id="fs">
                                                            <h2 id="sli1">Simplicity in Complexity</h2>
                                                        </div>
                                                    </div>

                                                </div> <!-- .et_pb_slide_description -->
                                            </div>
                                        </div> <!-- .et_pb_container -->

                                    </div> <!-- .et_pb_slide -->
                                    <div class="et_pb_slide et_pb_slide_3 et_pb_bg_layout_dark et_pb_media_alignment_center"
                                        data-slide-id="et_pb_slide_3">


                                        <div class="et_pb_container clearfix">
                                            <div class="et_pb_slider_container_inner">

                                                <div class="et_pb_slide_description">
                                                    <div class="et_pb_slide_content">
                                                        <div id="fs">
                                                            <h2 id="sli1">Join hands now and leave your compliances
                                                                burden on us</h2>
                                                        </div>
                                                    </div>

                                                </div> <!-- .et_pb_slide_description -->
                                            </div>
                                        </div> <!-- .et_pb_container -->

                                    </div> <!-- .et_pb_slide -->
                                </div> <!-- .et_pb_slides -->

                            </div> <!-- .et_pb_slider -->



                        </div> <!-- .et_pb_section -->

                        <div id="services"
                            class="et_pb_section et_pb_section_2 et_pb_with_background et_section_regular">




                            <div class="et_pb_row et_pb_row_1">
                                <div
                                    class="et_pb_column et_pb_column_4_4 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_1 et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h1>Our Services</h1>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                    <div
                                        class="et_pb_module et_pb_divider et_pb_divider_0 et_pb_divider_position_ et_pb_space">
                                        <div class="et_pb_divider_internal"></div>
                                    </div>
                                </div> <!-- .et_pb_column -->


                            </div> <!-- .et_pb_row -->
                            <div class="et_pb_row et_pb_row_2">
                                <div
                                    class="et_pb_column et_pb_column_1_3 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_0 et_animated et-waypoint">


                                        <span class="et_pb_image_wrap "><img
                                                src="/assets/wp-content/uploads/2020/02/jpeg/Audit.jpg" alt="" title=""
                                                srcset="" sizes="" /></span>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_2 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h4>Audit &#038; Assurance</h4>
                                            <p>Extensive experience in providing assurance services under various
                                                statutes across various industries for the past several years
                                                word.<br />
                                                <a routerLink="/services" (click)="scrollClick('audit')"><button
                                                        id="rm">Read More
                                                    </button></a>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_3 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_1 et_animated et-waypoint">


                                        <span class="et_pb_image_wrap "><img
                                                src="/assets/wp-content/uploads/2020/02/jpeg/INCOME_TAX.jpg" alt=""
                                                title="" srcset="" sizes="" /></span>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_3 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h4>Direct Taxes</h4>
                                            <p>All areas of taxation : Personal Tax, Corporate Tax. | Suggesting legal
                                                structure of business entities. | Transactional advisory&#8230;<br />
                                                <a routerLink="/services" (click)="scrollClick('direct')"><button
                                                        id="rm">Read More
                                                    </button></a>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_3 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div class="et_pb_module et_pb_image et_pb_image_2 et_animated et-waypoint">


                                        <span class="et_pb_image_wrap "><img
                                                src="/assets/wp-content/uploads/2020/02/jpeg/Gst.jpg" alt="" title=""
                                                srcset="" sizes="" /></span>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_4 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h4>Indirect Taxes</h4>
                                            <p>Advising client on establishment of indirect tax management system, in
                                                all areas i.e. GST, Customs, Foreign Trade Policies and&#8230;<br />
                                                <a routerLink="/services" (click)="scrollClick('indirecttax')"><button
                                                        id="rm">Read More
                                                    </button></a>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->


                            </div> <!-- .et_pb_row -->
                            <div class="et_pb_row et_pb_row_3">
                                <div
                                    class="et_pb_column et_pb_column_1_3 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_3 et_animated et-waypoint">


                                        <span class="et_pb_image_wrap "><img
                                                src="/assets/wp-content/uploads/2020/02/jpeg/Capture31.jpg" alt=""
                                                title="" srcset="" sizes="" /></span>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_5 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h4>Business Advisory</h4>
                                            <p>Business advisory services tackle all aspects of business from managing
                                                finances, marketing your product or service better and more
                                                widely&#8230;<br />
                                                <a routerLink="/services" (click)="scrollClick('business')"><button
                                                        id="rm">Read More
                                                    </button></a>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_3 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_3 et_animated et-waypoint">


                                        <span class="et_pb_image_wrap "><img
                                                src="/assets/wp-content/uploads/2020/02/jpeg/accounting.jpg" alt=""
                                                title="" srcset="" sizes="" /></span>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_5 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h4>Accounting</h4>
                                            <p> We offers services of Maintainance of Accounts for your business
                                                -Timely and Accurate as Per Accounting Standards and Indian GAAP...
                                                <!-- <br> Payroll Management -->


                                                <!-- <ul>
                                                <li>Timely and Accurate as Per Accounting Standards and Indian GAAP.
                                                </li>
                                                <li>Payroll Management</li>
                                                <li>Remotely online accounting onsite.</li>
                                            </ul> -->
                                                <br />
                                                <a routerLink="/services" (click)="scrollClick('accounting')"><button
                                                        id="rm">Read More
                                                    </button></a>

                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->

                                <div
                                    class="et_pb_column et_pb_column_1_3 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_3 et_animated et-waypoint">


                                        <span class="et_pb_image_wrap "><img
                                                src="/assets/wp-content/uploads/2020/02/jpeg/MCA-naming-guidelines.jpg"
                                                alt="" title="" srcset="" sizes="" /></span>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_5 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h4>MCA/ ROC</h4>
                                            <p>We have rich experience in handling all MCA and ROC Compliances like-
                                                Incorporation of New Company/ LLP/ Trust/ NGO...
                                                <!-- Annual ROC forms- MGT-7 and AOC-4.						
                                            Other forms like- ADT-1 ,INC-22, DIR-3, MGT-14.						 -->


                                                <br />
                                                <a routerLink="/services" (click)="scrollClick('mca')"><button
                                                        id="rm">Read More
                                                    </button></a>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->
                            </div> <!-- .et_pb_row -->
                            <div class="et_pb_row et_pb_row_3">
                                <div
                                    class="et_pb_column et_pb_column_1_3 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_3 et_animated et-waypoint">


                                        <span class="et_pb_image_wrap "><img
                                                src="/assets/wp-content/uploads/2020/02/jpeg/other-services-garant.jpg"
                                                alt="" title="" srcset="" sizes="" /></span>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_5 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h4>Other Services</h4>
                                            <p>We also provide additional services like
                                                <br>TradeMark/ FSSAI Registration.
                                            </p>
                                            <!-- <ul>
                                                <li>TradeMark/ FSSAI Registration.
                                                </li>
                                                <li>Preparation of Project Reports/ CMA reports</li>
                                                <li>ESI/ PF/ MSME Registration.</li>
                                            </ul> -->

                                            <a routerLink="/services" (click)="scrollClick('other')"><button
                                                    id="rm">Read More
                                                </button></a>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->
                            </div>

                        </div> <!-- .et_pb_section -->
                        <div id="business-mana" class="et_pb_section et_pb_section_3 et_section_regular">




                            <div class="et_pb_row et_pb_row_4 et_animated">
                                <div
                                    class="et_pb_column et_pb_column_4_4 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_7 et_pb_bg_layout_light  et_pb_text_align_center">


                                        <div class="et_pb_text_inner">
                                            <h1><span style="color: #edf000;">Business</span> Communication Skills.</h1>
                                            <p>infinite possibilities. endless opportunities.</p>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->


                            </div> <!-- .et_pb_row -->


                        </div> <!-- .et_pb_section -->
                        <div class="et_pb_section et_pb_section_4 et_pb_with_background et_section_regular">




                            <div class="et_pb_row et_pb_row_5">
                                <div
                                    class="et_pb_column et_pb_column_4_4 et_pb_column_10  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_8 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h1>Industries</h1>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                    <div
                                        class="et_pb_module et_pb_divider et_pb_divider_1 et_pb_divider_position_ et_pb_space">
                                        <div class="et_pb_divider_internal"></div>
                                    </div>
                                </div> <!-- .et_pb_column -->


                            </div> <!-- .et_pb_row -->
                            <div class="et_pb_row et_pb_row_6">
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_11  et_pb_css_mix_blend_mode_passthrough">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_0 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="https://ahca.in/wp-content/uploads/2020/02/automobiles.png"
                                                        alt="" srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>Automobiles</span></h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_12  et_pb_css_mix_blend_mode_passthrough">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_1 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="../../assets/wp-content/uploads/2020/02/textile.png" alt=""
                                                        srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>Textile</span></h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_13  et_pb_css_mix_blend_mode_passthrough">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_2 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="https://ahca.in/wp-content/uploads/2020/02/telecom.png"
                                                        alt="" srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>Telecom</span></h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_14  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_3 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="../../assets/wp-content/uploads/2020/02/hospitality.png"
                                                        alt="" srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>Hospitality</span></h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->


                            </div> <!-- .et_pb_row -->
                            <div class="et_pb_row et_pb_row_7">
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_15  et_pb_css_mix_blend_mode_passthrough">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_4 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="https://ahca.in/wp-content/uploads/2020/02/information-technology.png"
                                                        alt="" srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>Information Technology</span></h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_16  et_pb_css_mix_blend_mode_passthrough">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_5 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="https://ahca.in/wp-content/uploads/2020/02/real-estate-1.png"
                                                        alt="" srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>Real Estate & construction</span>
                                                </h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_17  et_pb_css_mix_blend_mode_passthrough">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_6 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="https://ahca.in/wp-content/uploads/2020/02/media-and-entertianment.png"
                                                        alt="" srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>Media & Entertainment</span></h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_1_4 et_pb_column_18  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div
                                        class="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_7 et_animated et_pb_bg_layout_light  et_pb_text_align_left  et_pb_blurb_position_left">


                                        <div class="et_pb_blurb_content">
                                            <!-- <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img
                                                        src="https://ahca.in/wp-content/uploads/2020/02/white-goods-electricals.png"
                                                        alt="" srcset="" sizes=""
                                                        class="et-waypoint et_pb_animation_top" /></span></div> -->
                                            <div class="et_pb_blurb_container">
                                                <h4 class="et_pb_module_header"><span>White Goods / Electricals</span>
                                                </h4>

                                            </div>
                                        </div> <!-- .et_pb_blurb_content -->
                                    </div> <!-- .et_pb_blurb -->
                                </div> <!-- .et_pb_column -->


                            </div> <!-- .et_pb_row -->


                        </div> <!-- .et_pb_section -->
                        <div class="et_pb_section et_pb_section_5 et_pb_with_background et_section_regular">






                        </div> <!-- .et_pb_section -->

                        <div id="footer-bg" class="et_pb_section et_pb_section_7 et_section_regular">




                            <div class="et_pb_row et_pb_row_12">
                                <div
                                    class="et_pb_column et_pb_column_3_5 et_pb_column_24  et_pb_css_mix_blend_mode_passthrough">


                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_11 et_animated et_pb_bg_layout_light  et_pb_text_align_left">


                                        <div class="et_pb_text_inner">
                                            <h1><span style="color: #edf000;">REQUEST</span> A CALL BACK</h1>
                                            <p>Would you like to speak to one of our financial advisers over the phone?
                                                Just submit your details and we’ll be in touch shortly. You can also
                                                email us if you would prefer.</p>
                                        </div>
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->
                                <div
                                    class="et_pb_column et_pb_column_2_5 et_pb_column_25  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_12 et_animated et_pb_bg_layout_light  et_pb_text_align_left">

                                        <app-contactform [Show]="Show"></app-contactform>
                                        <!-- <div class="et_pb_text_inner">
                                            <div role="form" class="wpcf7" id="wpcf7-f114-p5-o1" lang="en" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <form action="/#wpcf7-f114-p5-o1" method="post" class="wpcf7-form"
                                                    novalidate="novalidate">
                                                    <div style="display: none;">
                                                        <input type="hidden" name="_wpcf7" value="114" />
                                                        <input type="hidden" name="_wpcf7_version" value="5.1.6" />
                                                        <input type="hidden" name="_wpcf7_locale" value="en" />
                                                        <input type="hidden" name="_wpcf7_unit_tag"
                                                            value="wpcf7-f114-p5-o1" />
                                                        <input type="hidden" name="_wpcf7_container_post" value="5" />
                                                    </div>
                                                    <p><label> Name<br />
                                                            <span class="wpcf7-form-control-wrap your-name"><input
                                                                    type="text" name="your-name" value="" size="40"
                                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                    aria-required="true" aria-invalid="false"
                                                                    placeholder="Name" /></span> </label></p>
                                                    <p><label> Email ID<br />
                                                            <span class="wpcf7-form-control-wrap your-email"><input
                                                                    type="email" name="your-email" value="" size="40"
                                                                    class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                    aria-required="true" aria-invalid="false"
                                                                    placeholder="Email ID" /></span> </label></p>
                                                    <p><label> Mobile No<br />
                                                            <span class="wpcf7-form-control-wrap tel-578"><input
                                                                    type="tel" name="tel-578" value="" size="40"
                                                                    class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                                                                    aria-required="true" aria-invalid="false"
                                                                    placeholder="Mobile No" /></span> </label></p>
                                                    <p><input type="submit" value="Send"
                                                            class="wpcf7-form-control wpcf7-submit" /></p>
                                                    <div class="wpcf7-response-output wpcf7-display-none"></div>
                                                </form>
                                            </div>
                                        </div> -->
                                    </div> <!-- .et_pb_text -->
                                </div> <!-- .et_pb_column -->


                            </div>
                            <!--.et_pb_row -->


                        </div> <!-- .et_pb_section -->
                    </div><!-- .et_builder_inner_content -->
                </div><!-- .et-l -->


            </div><!-- #et-boc -->
        </div> <!-- .entry-content -->


    </article> <!-- .et_pb_post -->



</div> <!-- #main-content -->