import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers

})
export class CarouselComponent implements OnInit {
  images = [
    {
      img: '../../assets/wp-content/uploads/2020/02/slide4.jpeg',
      cap: 'Growing your Business is now Easy'
    },
    {
      img: '../../assets/wp-content/uploads/2020/02/3175367.jpg',
      cap: 'Helping Enterpreneurs to start their own venture'
    },
    {
      img: '../../assets/wp-content/uploads/2020/02/slider-1.jpg',
      cap: 'Simplicity in Complexity'
    },
    {
      img: '../../assets/wp-content/uploads/2020/02/slider-2.jpg',
      cap: 'Join hands now and leave your compliances burden on us'
    }
  ];  
  constructor(config: NgbCarouselConfig) {
    //
    config.interval = 4000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
  }

}
