import { Component, OnInit } from '@angular/core';
import { CommenService } from '../servicess/commen.service';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  Show:boolean = false;
  detailInfo: any;
  constructor(private comm: CommenService) { }

  ngOnInit(): void {
    this.detailInfo = this.comm.OwnerDetail();
  }

}
