import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommenService {
 
  constructor() { }
  detailInfo: any = {
    phoneNo:'',mobileNO: '', address: '', email: '', whatsApp: '',
    fbPage: '', twitter: '', linkIn: '', pinterest: '', instagram: '',website:''
  };
  OwnerDetail(): any {
    return this.detailInfo = {
      mobileNo: '+91-7065941332',
      phoneNo:'+91-7503799224',
      whatsApp: '+917065941332',
      address: '04, Arjun Nagar, Krishna Nagar Metro Station, Delhi-110051',//'6127,Kaushik Complex, Main Road,Gandhi Nagar, Delhi - 110031',
      email: 'docs.mgaoffice@gmail.com',//'onlinetaxportal.ca@gmail.com',//'camohit055@gmail.com',
      fbPage: '#',
      twitter: '#',
      linkIn: '#',
      pinterest: '#',
      instagram: '#',
      website: 'www.mrgassociates.com'//'www.onlinetaxportal.com'
    };
  }
  PaymentGatewayConfig(): any {
    return this.detailInfo = {
    
    };
  }
}
