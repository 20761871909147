<div id="top-header">
    <div class="container clearfix">
        <div id="et-info">
            <ul class="et-social-icons2">

                <li class="">
                    <a id="et-info-phone" class="btn-link"
                        href="tel:{{detailInfo.mobileNo}}">{{this.detailInfo.mobileNo}}</a>
                </li>
                <li class=""><a id="et-info-phone" class="btn-link"
                        href="tel:{{detailInfo.phoneNo}}">{{this.detailInfo.phoneNo}}</a></li>
                <li class="">
                    <a href="mailto:{{detailInfo.email}}" class="btn-link"><span id=""> <img
                                src="../../assets/wp-content/uploads/2020/02/gmail2.png" width="25" height="25">
                            {{this.detailInfo.email}}</span></a>
                </li>
            </ul>

            <ul class="et-social-icons">

                <li class="et-social-icon">

                    <a href="whatsapp://send?phone=+917065941332">
                        <!-- <i class="fab fa-whatsapp"></i>  -->
                        <img src="../../assets/wp-content/uploads/2020/02/whatsapp.png" width="30" height="30"> Connect
                        on Whatsapp
                    </a>
                </li>
                <!-- <li class="et-social-icon et-social-facebook">
                        <a href="https://www.facebook.com/pages/Ahmad-Husain-Co/104690421129290" class="icon">
                            <span>Facebook</span>
                        </a>
                    </li> -->
                <!-- <li class="et-social-icon et-social-instagram">
                        <a href="https://www.instagram.com/ahcaindia/" class="icon">
                            <span>Instagram</span>
                        </a>
                    </li> -->

            </ul>
        </div> <!-- #et-info -->


        <!-- <div id="et-secondary-menu">
                <div class="et_duplicate_social_icons">
                    <ul class="et-social-icons">

                        <li class="et-social-icon et-social-facebook">
                            <a href="https://www.facebook.com/pages/Ahmad-Husain-Co/104690421129290" class="icon">
                                <span>Facebook</span>
                            </a>
                        </li>
                        <li class="et-social-icon et-social-instagram">
                            <a href="https://www.instagram.com/ahcaindia/" class="icon">
                                <span>Instagram</span>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>  -->
        <!-- #et-secondary-menu -->

    </div> <!-- .container -->
</div> <!-- #top-header -->


<header id="main-header" data-height-onload="80">
    <div class="container clearfix et_menu_container">
        <div class="logo_container">
            <span class="logo_helper"></span>
            <a href="index.html">
                <img src="/assets/wp-content/uploads/2020/02/logo-1-1.png" alt="mrgassociates" id="logo"
                    data-height-percentage="60" />
            </a>
        </div>
        <div id="et-top-navigation" data-height="80" data-fixed-height="40">
            <nav id="top-menu-nav">
                <ul id="top-menu" class="nav">
                    <li id="menu-item-21"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-21">
                        <!-- <a href="index.html" aria-current="page">Home</a></li> -->
                        <a class="nav-link" routerLinkActive="active" routerLink="/home">Home</a>
                    </li>
                    <li id="menu-item-168"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-168">
                        <!-- <a
                                href="about-us/index.html">About Us</a></li> -->
                        <a class="nav-link" routerLinkActive="active" routerLink="/aboutus">About Us</a>
                    </li>
                    <!-- <li id="menu-item-167"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-167">
                        <a class="nav-link" routerLinkActive="active" routerLink="/services">Services</a>
                    </li> -->
                    <li id="menu-item-167"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-167">
                        <div class="dropdown">
                            <button class="dropbtn nav-link" routerLinkActive="active">Services
                                <i class="fa fa-caret-down"></i>
                            </button>
                            <div class="dropdown-content">
                                <a class="nav-link" (click)="scrollClick('audit')" routerLink="/services">Audit &
                                    Assurance</a>
                                <a class="nav-link" (click)="scrollClick('directtax')" routerLink="/services">Direct
                                    Taxes</a>
                                <a class="nav-link" (click)="scrollClick('indirecttax')" routerLink="/services">Indirect
                                    Taxes</a>
                                <a class="nav-link" (click)="scrollClick('business')" routerLink="/services">Business
                                    Advisory</a>
                                <a class="nav-link" (click)="scrollClick('accounting')"
                                    routerLink="/services">Accounting</a>
                                <a class="nav-link" (click)="scrollClick('mca')" routerLink="/services">MCA/ ROC</a>
                                <a class="nav-link" (click)="scrollClick('other')" routerLink="/services">Other
                                    Services</a>
                            </div>
                        </div>
                    </li>

                    <li id="menu-item-166"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-166">
                        <a class="nav-link" routerLinkActive="active" routerLink="/contactus">Contact Us</a>
                    </li>
                    <li id="menu-item-165"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-165">
                        <a class="nav-link" routerLinkActive="active" routerLink="/pay">Payment</a>
                    </li>

                </ul>
            </nav>




            <div id="et_mobile_nav_menu">
                <div class="mobile_nav closed">
                    <span class="select_page">Select Page</span>
                    <span class="mobile_menu_bar mobile_menu_bar_toggle"></span>
                </div>
            </div>
        </div> <!-- #et-top-navigation -->
    </div> <!-- .container -->
    <!-- <div class="et_search_outer">
            <div class="container et_search_form_container">
                <form role="search" method="get" class="et-search-form" action="https://ahca.in/">
                    <input type="search" class="et-search-field" placeholder="Search &hellip;" value="" name="s"
                        title="Search for:" />
                </form>
                <span class="et_close_search_field"></span>
            </div>
        </div> -->
</header> <!-- #main-header -->
<script>
    /* When the user clicks on the button, 
    toggle between hiding and showing the dropdown content */
    function myFunction() {
        document.getElementById("myDropdown").classList.toggle("show");
    }

    // Close the dropdown if the user clicks outside of it
    window.onclick = function (e) {
        if (!e.target.matches('.dropbtn')) {
            var myDropdown = document.getElementById("myDropdown");
            if (myDropdown.classList.contains('show')) {
                myDropdown.classList.remove('show');
            }
        }
    }
</script>